html, body, #root{
	height: 100%;
	margin: 0;
	padding: 0;
}
.main{
	width:100%;
	height: 100%;
	display: flex;
	flex-direction:column;
	align-items:stretch;
	justify-content:stretch;
}
.navbar{
	background-color:#0f0f0f;
}
.contents{
	display:flex;
	width:100%;
	height:100%;
	align-items:stretch;
	flex-wrap:wrap;
}
.sidebarCol{
	flex-basis:225px;
	flex-grow:1;
	background-color:#0f0f0f;
	padding-top:30px;
}
.contentCol{
	padding:20px;
	flex-basis:0;
	flex-grow:999;
}
nav.menu{
	position:fixed;
}
nav.menu a{
	text-decoration:none;
	display:block;
	color:#fff;
	padding:.5rem 1.1rem;
	margin:.3rem 0;
	border-left:3px solid transparent;
}
nav.menu a:hover, nav.menu a.active{
	color:#ffb21d !important;
	border-left:3px solid #ffb21d
}
nav.menu a svg{margin:0 .5rem .1rem 0}
.pagination{
	display:flex;
	justify-content:center;
	align-items:center;
	margin:1rem 0;
}
.pagination span{
	margin:0 1rem;
}
/* Map Left Side Full Card */
.mapSideCard{
	flex-basis:35%;
	flex-grow:1;
	background:#1f1f1f;
	color:#fff;
	display:flex;
	flex-direction:column
}
.mapSideCard .bar{
	padding:10px 15px
}
.mapSideCard .body{
	padding:10px 15px;
}
.mapSideCard .bar .close:hover{
	cursor:pointer;
}
.loading{
	flex-grow:999;
	display:flex;
	align-items:center;
	justify-content:center;
	min-height:300px;
}
.loading div{
	text-align:center
}
.gear.p, .gear.n{color:#ffb21d}
.gear.d{color:#28bd5a}
.gear.r{color:#ef6149}
.lights .on::after{
	content: "⬤";
	color: #28bd5a
}
.lights .off::after{
	content: "⬤";
	color: #414141
}

/* Component Title */
.titleBar{
	display:flex
}
.titleBar>*{
	flex-basis:50%
}
.titleBar>div{
	display:flex;
	justify-content:end
}
.titleBar>div>div{
	padding:5px
}
/* Tickets */
.tickets .ticketId{
	font-size:12px;
	font-weight:normal;
	margin:4px 0;
	color:gray;
	float:right
}
.tickets select{padding:5px 5px 6px 5px;margin:-6px 5px 0 0}
.tickets .details{
	display:flex;
	flex-direction:row;
	list-style:none;
	padding:0;margin:5px 0 0 0;
	font-size:13px;
}
.tickets .details, .tickets .details a{
	color:grey
}
@media (min-width:1440px) {
	.details li{min-width:200px}
}
.tickets .details a:hover{
	text-decoration:none;
}
.tickets .details li:before{
	content: "⚬";
	color:grey;
	padding: 0 20px;
}
x.tickets .details li:last-child:after{
	content: "";
}
.details button{
	margin-top:-5px
}
.response{
	border-left:6px solid lightgray;
	padding:5px 0 5px 10px;
	font-style: italic;
	overflow: hidden;
}
.response.hide{
	border:0;
	padding:0;margin:0;
	height:0;
}
.tickets .responseBox{
	background:#f7f7f7;
}
.tickets .responseBox textarea{
	resize:vertical;
	padding:5px 10px; margin-bottom:10px;
	width:100%; height:150px;
}
.tickets .responseBox .charCount{
	margin-top:-10px;
	margin-right:5px;
	float:right;
	font-size:14px;
	color:gray
}

.status1, .status2, .status3, .status4{
	padding:3px 6px 4px 6px;
	border-radius:5px
}
/* new not assigned */
.status1{
	background:rgb(255, 178, 29, .3)
}
/* assigned need response */
.status2{
	background:rgb(182, 44, 26, .3)
}
/* responsed */
.status3{
	background:rgb(40, 189, 90, .3)
}
/* archived */
.status4{
	background:rgb(73, 125, 239, .3)
}


.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-height: 100vh !important;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
	min-height: 100vh !important;
	width: 100vw;
	margin-left: -1rem;
	-webkit-transition: margin .25s ease-out;
	-moz-transition: margin .25s ease-out;
	-o-transition: margin .25s ease-out;
	transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
	padding: 0.875rem 1.25rem;
	font-size: 1.2rem;
}

#page-content-wrapper {
	min-width: 0;
	width: 100%;
}